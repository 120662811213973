import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  loading: boolean = false;
  titleList:string[];

  constructor(
    private router: Router,
    private titleService:Title
    ) {
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

  ngOnInit(): void {
    
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        const url = val.url.substring(val.url.indexOf('/') + 1);
        if(url == ' ' || url == undefined || url.length == 0 || url == 'home') {
          this.titleService.setTitle(this.getTitleFromTitleList(0));
        } else if(url == 'about-us') {
          this.titleService.setTitle(this.getTitleFromTitleList(1));
        } else if(url == 'service') {
          this.titleService.setTitle(this.getTitleFromTitleList(2));
        } else if(url == 'project') {
          this.titleService.setTitle(this.getTitleFromTitleList(3));
        } else if(url == 'client') {
          this.titleService.setTitle(this.getTitleFromTitleList(4));
        } else if(url == 'enquiry') {
          this.titleService.setTitle(this.getTitleFromTitleList(5));
        } else if(url == 'career') {
          this.titleService.setTitle(this.getTitleFromTitleList(6));
        } else {
          this.titleService.setTitle(this.getTitleFromTitleList(7));
        }
      }
    });

  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }

  getTitleFromTitleList(index:number):string {
    this.titleList = [
      'ShreeCommercial : Welcome to Shree Commercial Interiors Pvt. Ltd.',
      'ShreeCommercial : About Us',
      'ShreeCommercial : Service',
      'ShreeCommercial : Project',
      'ShreeCommercial : Clients',
      'ShreeCommercial : Enquiry',
      'ShreeCommercial : Career',
      'ShreeCommercial : Contact Us'
    ];

    const result = this.titleList[index];
    return result;
  }

}
