<footer>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <a (click)="goto('home')" class="footer-logo">
          <img src="../../../assets/images/footer-logo.png" alt="Shree Commerciial Logo" class="img-responsive" />
        </a>
        <p class="address">
          <a href="mailto:info@shreecommercial.com" style="color: inherit;">info@shreecommercial.com</a> /
          <a href="mailto:mahendra@shreecommercial.com" style="color: inherit;">mahendra@shreecommercial.com</a>
          <br>
        </p>
        <table class="phone-table">
          <tbody>
            <tr>
              <td>
                <app-svg [isPhoneVisible]="true"></app-svg>
              </td>
              <td><a href="tel:+2226691723">+91 2226691723 / 1724</a></td>
            </tr>
          </tbody>
        </table>

        <p></p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div class="link-wrapper">
          <h2>Links</h2>
          <div class="footer-link-divider"></div>
          <ul class="footer-link">
            <li (click)="goto('home')">Home</li>
            <li (click)="goto('about-us')">About Us</li>
            <li (click)="goto('service')">Services</li>
            <li (click)="goto('client')">Clients</li>
            <li (click)="goto('enquiry')">Enquiry</li>
            <li (click)="goto('career')">Career</li>
            <li (click)="goto('contact')">Contact</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
        <div class="footer-social-wrapper">
          <h2>Follow us</h2>
          <div class="footer-link-divider"></div>
          <ul class="footer-social-link">
            <li *ngIf="false">
              <a href="https://www.facebook.com/polycrack" target="_blank">
                <app-svg [isFacebookVisible]="true"></app-svg>
              </a>
            </li>
            <li *ngIf="false">
              <a href="https://www.instagram.com/polycrackgroup/" target="_blank">
                <app-svg [isInstagramVisible]="true"></app-svg>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/shree-commercial-interiors-pvt-ltd" target="_blank">
                <app-svg [isLinkedInVisible]="true"></app-svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="after-footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" [ngClass]="showMobileView ? 'text-center' : 'text-left'">
                <span>Privacy Policy</span>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-center">
                <span>© {{currentYear}} Shreecommercial Pvt. Ltd. All Rights Reserved.</span>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-center">
                <a href="http://www.spakcomm.com" target="_blank">
                    Designed with
                    <app-svg [isHeartVisible]="true"></app-svg>
                    by Spakcomm
                </a>
            </div>
        </div>
    </div>
</div>
