import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/service/base.service';
import { HelperService } from '../../service/helper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear:  number;
  showMobileView: boolean;

  constructor(
    private baseService: BaseService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.showMobileView = this.helperService.isMobile;
  }

  goto(path:string):void {
    this.baseService.route(path);
  }

}
