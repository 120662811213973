import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  public get isMobile():boolean {
    if(window.innerWidth < 768) {
      return true;
    } else {
      return false;
    }
  }

}
