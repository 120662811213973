import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit {

  @Input() isMenuVisible: boolean = false;
  @Input() isFacebookVisible: boolean = false;
  @Input() isInstagramVisible: boolean = false;
  @Input() isLinkedInVisible: boolean = false;
  @Input() isPhoneVisible: boolean = false;
  @Input() isHeartVisible: boolean = false;
  @Input() isCloseVisible: boolean = false;
  @Input() isLearnMoreVisible: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
