import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/service/base.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isMenuVisible: boolean = false;
  showMenu: string;
  showOverlay: string;

  constructor(
    private baseService: BaseService
  ) { }

  ngOnInit(): void {
  }

  toggleMenu():void {
    this.isMenuVisible = !this.isMenuVisible;
    this.showMenu = this.isMenuVisible ? 'show-menu' : null;
    this.showOverlay = this.isMenuVisible ? 'show' : null;
  }

  goto(path:string) {
    this.baseService.route(path);
    this.toggleMenu();
  }

  gotoByLogo(path:string):void {
    this.baseService.route(path);
  }

}
