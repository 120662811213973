<div class="contact-address" *ngIf="city && address">
    <div class="brow-design" *ngIf="isBrownElementVisible"></div>
    <h4 *ngIf="city">{{city}}</h4>
    <span class="border"></span>
    <p *ngIf="address">
       {{address}}
    </p>
    <p *ngIf="telephone">
      Tel No: {{telephone}}
    </p>
    <p *ngIf="email">
      {{email}}
    </p>
</div>
