<header>
    <app-svg *ngIf="!isMenuVisible" [isMenuVisible]="true" (click)="toggleMenu()"></app-svg>
    <app-svg *ngIf="isMenuVisible" [isCloseVisible]="true" (click)="toggleMenu()"></app-svg>
    <img (click)="gotoByLogo('home')" src="../../../assets/images/logo.png" alt="Shree Commercial Logo" class="img-responsive logo-img" />
    <ul class="list-inline social-icon">
        <li *ngIf="false"><app-svg [isFacebookVisible]="true"></app-svg></li>
        <li *ngIf="false"><app-svg [isInstagramVisible]="true"></app-svg></li>
        <li>
          <a href="https://www.linkedin.com/company/shree-commercial-interiors-pvt-ltd" target="_blank">
            <app-svg [isLinkedInVisible]="true"></app-svg>
          </a>
        </li>
    </ul>
</header>

<div class="menu-wrapper" [class]="showMenu">
    <nav>
        <ul>
            <li (click)="goto('home')">Home</li>
            <li (click)="goto('about-us')">About Us</li>
            <li (click)="goto('service')">Services</li>
            <li (click)="goto('project')">Projects</li>
            <li (click)="goto('client')">Clients</li>
            <li (click)="goto('enquiry')">Enquiry</li>
            <li (click)="goto('career')">Career</li>
            <li (click)="goto('contact')">Contact</li>
        </ul>
    </nav>
</div>

<div class="overlay" [class]="showOverlay"></div>
