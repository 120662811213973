<div *ngIf="isCarousel" id="myCarousel" class="carousel slide carousel-fade" data-ride="carousel" data-pause="false">
    <div class="carousel-inner">
      <div class="item active">
        <img src="../../../assets/images/carousel/1.jpg" alt="Los Angeles" />
      </div>

      <div class="item">
          <img src="../../../assets/images/carousel/2.jpg" alt="Los Angeles" />
      </div>

      <div class="item">
          <img src="../../../assets/images/carousel/3.jpg" alt="Los Angeles" />
      </div>

      <div class="item">
        <img src="../../../assets/images/carousel/4.jpg" alt="Los Angeles" />
      </div>

      <div class="item">
        <img src="../../../assets/images/carousel/5.jpg" alt="Los Angeles" />
      </div>

      <div class="item">
        <img src="../../../assets/images/carousel/6.jpg" alt="Los Angeles" />
      </div>

    </div>

    <a class="left carousel-control" href="#myCarousel" data-slide="prev">
      <span class="glyphicon glyphicon-chevron-left"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#myCarousel" data-slide="next">
      <span class="glyphicon glyphicon-chevron-right"></span>
      <span class="sr-only">Next</span>
    </a>
</div>

  <!--Testimonial-->
  <div class="test-carousel">
    <h3 *ngIf="testimonailHeading">{{testimonailHeading}}</h3>
    <p *ngIf="testimonailParagraph" class="test-heading-para">{{testimonailParagraph}}</p>
    <div *ngIf="isTestimonial" id="testiCarousel" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="item test-item active">
          <div class="row">
            <div class="col-lg-10 col-md-10 col-sm-9 col-xs-12">
              <p>
                We would like to thank the entire Shree Commercial Interiors Pvt. Ltd. team for the professional and good quality work done at our office in Mumbai admeasuring 27,200 sqft.
              </p>
              <blockquote class="adjust2">
                <p>Veena Mishra</p>
                <div class="comp-design">
                  <b>Designation:</b> <span>General Manager Strategic Alliances</span>
                  <br />
                  <b>Company:</b> <span>Ingram Micro </span>
                </div>
              </blockquote>
            </div>
          </div>
        </div>
        <div class="item test-item">
          <div class="row">
            <div class="col-lg-10 col-md-10 col-sm-9 col-xs-12">
              <p>
                M/s. Shree Commercial is our regular vendor since 2016 and has completed many of our corporate offices and sales offices worth 200+ million across India. They have a very good planning and as well as execution team. We are very much satisfied with the workmanship, Quality, Cost and the Timelines. We wish them all the best for their future projects
              </p>
              <blockquote class="adjust2">
                <p>Yadala Venkata Srihari</p>
                <!-- <div class="comp-design">
                  <b>Designation:</b> <span>Lorem Ipsum</span>
                  <br />
                  <b>Company:</b> <span>Lorem Ipsum</span>
                </div> -->
              </blockquote>
            </div>
          </div>
        </div>
      </div>

      <a class="left carousel-control" href="#testiCarousel" data-slide="prev">
        <span class="glyphicon glyphicon-chevron-left"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control" href="#testiCarousel" data-slide="next">
        <span class="glyphicon glyphicon-chevron-right"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
  <!--Testimonial-->


