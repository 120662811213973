import { Component, Input, OnInit } from '@angular/core';
import { BaseService } from '../../service/base.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() currentHeading: string;

  constructor(
    private baseService: BaseService
  ) { }

  ngOnInit(): void {
  }

  goto(path:string):void {
    this.baseService.route(path);
  }

}
