import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(
    private router: Router,
    private http:HttpClient
  ) { }
  
  public route(path: string):void {
    this.router.navigateByUrl(path);
  }

  public getPrjectData():Observable<any> {
    return this.http.get('assets/data/project.json');
  }

  public getClientDate():Observable<any> {
    return this.http.get('assets/data/client.json');
  }

}
