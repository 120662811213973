import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appServiceCollapse]'
})
export class ServiceCollapseDirective implements OnInit {

  isExapnd:boolean = false;

  constructor(
    private elem: ElementRef,
    private renederer: Renderer2
  ) { }

  ngOnInit() {
    console.log(this.elem);
    console.log(this.elem.nativeElement.children[2].attributes[1].nodeValue);
  }

  @HostListener('click') onClick() {
    //console.log(this.elem);
    //console.log(this.elem.nativeElement.closest('.panel'));
    //this.elem.nativeElement.parentElement.nextElementSibling.classList.toggle('in');
    //this.elem.nativeElement.closest('.panel').classList.toggle('in');
    //console.log(this.elem.nativeElement.closest('.panel').classList.toggle('Demo'));
    //console.dir(this.elem.nativeElement.closest('.panel-default'));
     this.elem.nativeElement.closest('.panel-default').querySelector('.panel-collapse').classList.toggle('in');
     //this.elem.nativeElement.closest('.panel-default').nextElementSibling.querySelector('.panel-collapse').classList.remove('in');
    //  if(this.elem.nativeElement.closest('.panel-default').previousElementSibling) {
    //    this.elem.nativeElement.closest('.panel-default').previousElementSibling.querySelector('.panel-collapse').classList.remove('in');
    //  }
    //  if(this.elem.nativeElement.closest('.panel-default').nextElementSibling) {
    //   this.elem.nativeElement.closest('.panel-default').nextElementSibling.querySelector('.panel-collapse').classList.remove('in');
    //  }

    console.dir(this.elem.nativeElement);
    //console.dir(this.elem.nativeElement.children[2]);
    //console.log(this.elem.nativeElement.children[2].attributes.src.value = 'rrr');
    //this.elem.nativeElement.children[2].attributes.src.value = this.isExapnd ? '../../../assets/images/services/minus.png' : '';

    this.isExapnd = !this.isExapnd;

    if(this.isExapnd) {
      this.elem.nativeElement.children[2].attributes.src.value = '../../../assets/images/services/minus.png';
    } else {
      this.elem.nativeElement.children[2].attributes.src.value = '../../../assets/images/services/plus.png';
    }

  }

  @HostListener('mouseenter') onMouseEnter() {
    this.renederer.setStyle(this.elem.nativeElement, 'color', '#98242a');
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.renederer.setStyle(this.elem.nativeElement, 'color', '#000');
  }



}
