import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from 'src/app/component/header/header.component';
import { FooterComponent } from 'src/app/component/footer/footer.component';
import { SvgComponent } from 'src/app/component/svg/svg.component';
import { SliderComponent } from '../../component/slider/slider.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbComponent } from 'src/app/component/breadcrumb/breadcrumb.component';
import { LoaderComponent } from 'src/app/component/loader/loader.component';
import { ContactAddressComponent } from 'src/app/component/contact-address/contact-address.component';
import { ServiceCollapseDirective } from 'src/app/directive/service-collapse.directive';

@NgModule({
  declarations: [
    HeaderComponent, FooterComponent, SvgComponent, SliderComponent, BreadcrumbComponent, LoaderComponent,
    ContactAddressComponent, ServiceCollapseDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CommonModule, HeaderComponent, FooterComponent, SvgComponent, SliderComponent, ReactiveFormsModule, BreadcrumbComponent, LoaderComponent,
    ContactAddressComponent, ServiceCollapseDirective
  ]
})
export class SharedModule { }
