import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-address',
  templateUrl: './contact-address.component.html',
  styleUrls: ['./contact-address.component.scss']
})
export class ContactAddressComponent implements OnInit {

  @Input() city: string;
  @Input() address: string;
  @Input() isBrownElementVisible: boolean = false;
  @Input() telephone: number;
  @Input() email:string;

  constructor() { }

  ngOnInit(): void {
  }

}
